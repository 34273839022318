<template>
  <div class="content">
    <app-tabs v-if="userIsAdmin" :tabs="tabs" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'NumbersLayout',
  data() {
    return {
      tabs: [
        { name: 'numbers-list', label: 'Номера' },
        { name: 'numbers-program', label: 'Программа' },
        { name: 'grades-list', label: 'Оценочные листы' },
        { name: 'award', label: 'Награждение' },
      ],
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  }
}
</script>
